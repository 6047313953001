import * as React from "react"
import { Link, graphql } from "gatsby"

import { BiCalendarAlt } from "react-icons/bi";
import { BiPurchaseTag } from "react-icons/bi";

import Layout from "../components/layout"
import Seo from "../components/seo"

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const BlogIndex = ({ data, location }) => {
  const posts = data.allMicrocmsBlogs.nodes;

  return (
    <Layout>
      <Seo title="" />
      <main>
      <ul className="index">
      {posts.map(post => {
        return(
        <li key={post.id}>
          <Link to={`/${post.blogsId}`}>
            <img src={`${post.eyecatch.url}`}></img>
            <div className="article-data">
              <h2>{post.title}</h2>
              <div className="tags">
                {post.tag.map(tag => {
                  return(
                    // <div><BiPurchaseTag/><Link to={`/${tag}`}>{tag}</Link></div>
                    <div><BiPurchaseTag/>{tag}</div>
                  )
                })}
              </div>
              <div className="date"><BiCalendarAlt/>{dayjs.utc(post.publishedAt).tz('Asia/Tokyo').format('YYYY-MM-DD')}</div>
            </div>
          </Link>
        </li>
        )
      })}
      </ul>
      </main>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    allMicrocmsBlogs(sort: { fields: [publishedAt], order: DESC }){
      nodes {
        title
        blogsId
        publishedAt
        content
        tag
        eyecatch{
          url
        }
      }
    }
  }
`